<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <loading-spinner />
    <error-message-dialog />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_inoutHistoryList" />
      <!-- 入出庫一覧照会画面 -->
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- 取引先 -->
            <div class="search-autocomplete first-search-item" style="width: 250px">
              <CClientInput
                v-model="searchForm.supplierSelected"
                :required="true"
                :auto-select-first="true"
              />
            </div>
            <!-- <span class="require">*</span> -->
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 品番・品名 -->
            <div class="search-autocomplete" style="width: 250px">
              <CItemInput
                v-model="searchForm.itemSelected"
                :clientSid="searchForm.supplierSelected"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 所属 -->
            <div class="search-autocomplete" style="width: 250px">
              <CBelongInput v-model="searchForm.affiliationSelected" />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 部品要求者 -->
            <div class="search-autocomplete" style="width: 250px">
              <CUserInput
                v-model="searchForm.partsRequesterSelected"
                :label="$t('label.lbl_partsRequester')"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- グループ -->
            <div class="search-autocomplete" style="width: 250px">
              <v-text-field
                outlined
                dense
                maxlength="200"
                v-model="searchForm.txt_groupName"
                :label="$t('label.lbl_groupName')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 管理番号 -->
            <div class="search-autocomplete" style="width: 250px">
              <v-text-field
                outlined
                dense
                maxlength="20"
                v-model="searchForm.managementNumber"
                :label="$t('label.lbl_managementNoJP')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 納品種別 -->
            <div class="search-autocomplete" style="width: 250px">
              <CCodeInput
                v-model="searchForm.deliveryType"
                :codeType="appConfig.DELIVERY_TYPE_DIV"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row style="margin-top: 20px">
            <!-- カレンダー -->
            <!-- 入出荷日From-->
            <div class="first-search-item date-style">
              <v-text-field
                outlined
                dense
                v-model="inOutScheduleDateFrom"
                :label="$t('label.lbl_inOutScheduleDate') + '（From）'"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>

            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuFrom"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="inOutScheduleDateFromCal"
                  @input="dateMenuFrom = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- カレンダーの間 -->
            <div class="from_to">～</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入出荷日To-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="inOutScheduleDateTo"
                :label="$t('label.lbl_inOutScheduleDate') + '（To）'"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              >
              </v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuTo"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="inOutScheduleDateToCal"
                  @input="dateMenuTo = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevToDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextToDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダー終わり -->

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 出庫依頼有無 -->
            <div class="search-autocomplete" style="width: 250px">
              <CCodeInput
                v-model="searchForm.outboundRequest"
                :codeType="appConfig.OUT_REQUEST_DIV"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 倉庫-->
            <div class="search-autocomplete" style="float: left">
              <CWarehouseInput v-model="searchForm.warehouseSelected" />
            </div>
          </v-row>
          <v-row class="d-flex justify-space-between gap-3 align-center">
            <!--ボタン領域-->
            <div>
              <!-- 検索ボタン -->
              <v-btn
                color="primary"
                id="get-search"
                class="api-btn ml-0"
                @click="clickSearchBtn()"
                >{{ $t("btn.btn_search") }}</v-btn
              >
              <!-- 画面クリアボタン -->
              <v-btn color="primary" id="btn-search" class="api-btn" @click="clearBtnDialog()">{{
                $t("btn.btn_clear")
              }}</v-btn>
            </div>
            <div class="btn-search-area" style="float: right">
              <!--データ出力ボタン-->
              <v-btn class="get-btn" @click="downloadList(true)">{{
                $t("btn.btn_outPutData")
              }}</v-btn>
            </div>
          </v-row>
          <!-- 4行目 -->
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>{{
                  $t("label.lbl_total") + "：" + totalCount + $t("label.lbl_number")
                }}</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100(100)" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="550px"
          class="table-style"
        >
          <!-- 品番・品名 -->
          <template v-slot:[`item.product`]="{ item }">
            <c-table-column-tooltip v-model="item.productCd" />
            <c-table-column-tooltip v-model="item.productName" />
          </template>
          <!-- 所属 -->
          <template v-slot:[`item.affiliation`]="{ item }">
            <c-table-column-tooltip v-model="item.affiliation" />
          </template>
          <!-- 部品要求者 -->
          <template v-slot:[`item.partsRequester`]="{ item }">
            <c-table-column-tooltip v-model="item.partsRequester" />
          </template>
          <!-- グループ -->
          <template v-slot:[`item.group`]="{ item }">
            <c-table-column-tooltip v-model="item.group" />
          </template>
          <!-- 倉庫 -->
          <template v-slot:[`item.warehouse`]="{ item }">
            <c-table-column-tooltip v-model="item.warehouse" />
          </template>
          <!-- 納品種別 -->
          <template v-slot:[`item.deliveryType`]="{ item }">
            <c-table-column-tooltip v-model="item.deliveryType" />
          </template>
          <!-- コントロールNo -->
          <template v-slot:[`item.controlNo`]="{ item }">
            <c-table-column-tooltip v-model="item.controlNo" />
          </template>
          <!-- 出庫依頼者 -->
          <template v-slot:[`item.outBoundRequester`]="{ item }">
            <c-table-column-tooltip v-model="item.outBoundRequester" />
          </template>
          <!-- 出荷No -->
          <template v-slot:[`item.shippingNo`]="{ item }">
            <c-table-column-tooltip v-model="item.shippingNo" />
          </template>
          <!-- 出荷作業者 -->
          <template v-slot:[`item.shipper`]="{ item }">
            <c-table-column-tooltip v-model="item.shipper" />
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :okAction="infoDialog.okAction"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import CClientInput from "@/components/CClientInput.vue";
import CWarehouseInput from "@/components/CWarehouseInput.vue";
import CItemInput from "@/components/CItemInput.vue";
import CBelongInput from "@/components/CBelongInput.vue";
import CUserInput from "@/components/CUserInput.vue";
import CCodeInput from "@/components/CCodeInput.vue";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";

export default {
  name: appConfig.SCREEN_ID.P_MNG_101,
  mixins: [commonMixin, commonRules],
  components: {
    CClientInput,
    CItemInput,
    CBelongInput,
    CUserInput,
    CWarehouseInput,
    CCodeInput,
    ConfirmDialog,
    SimpleDialog,
  },
  data: () => ({
    // 初期データ
    defaultData: [],
    alertMessage: "",
    u_10: false,
    searchForm: {
      // 取引先
      supplierSelected: "",

      // 品番/品名
      itemSelected: "",

      //  所属
      affiliationSelected: "",

      // 部品要求者
      partsRequesterSelected: "",

      // グループ
      txt_groupName: "",

      // 管理番号
      managementNumber: "",

      // 納品種類
      deliveryType: "",

      // 出庫依頼有無
      outboundRequest: "",

      // 倉庫
      warehouseSelected: "",
    },

    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: true,
    },

    // 入出荷日FROM
    inOutScheduleDateFrom: "",
    inOutScheduleDateFromCal: "",
    // 入出荷日Fromカレンダー
    dateMenuFrom: false,

    // 入出荷日TO
    inOutScheduleDateTo: "",
    inOutScheduleDateToCal: "",
    // 入出荷日Toカレンダー
    dateMenuTo: false,
    // 総件数
    totalCount: 0,
    // メニュー
    openMenu: null,
    dateMenu: false,
    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",

    // テーブルフッター項目
    inputList: [],
    // 検索リスト
    inOutList: [],
    // テーブルヘッダ項目
    headerItems: [
      // 入荷実績日
      {
        text: i18n.tc("label.lbl_arrivalacheivementDate"),
        value: "receivingDate",
        width: "150px",
        align: "left",
        sortable: false,
      },
      // 入荷数
      {
        text: i18n.tc("label.lbl_shipQty"),
        value: "receivingStock",
        width: "150px",
        align: "center",
        sortable: false,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "product",
        width: "250px",
        align: "left",
        sortable: false,
      },
      // 所属
      {
        text: i18n.tc("label.lbl_belong"),
        value: "affiliation",
        width: "100px",
        align: "left",
        sortable: false,
      },
      // 部品要求者
      {
        text: i18n.tc("label.lbl_partsRequester"),
        value: "partsRequester",
        width: "150px",
        align: "left",
        sortable: false,
      },
      // グループ
      {
        text: i18n.tc("label.lbl_groupName"),
        value: "group",
        width: "150px",
        align: "left",
        sortable: false,
      },
      // 管理番号
      {
        text: i18n.tc("label.lbl_managementNoJP"),
        value: "controlNumber",
        align: "left",
        width: "250px",
        sortable: false,
      },

      // 倉庫
      {
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        align: "left",
        width: "150px",
        sortable: false,
      },

      // 納品種別
      {
        text: i18n.tc("label.lbl_deliveryType"),
        value: "deliveryType",
        align: "left",
        width: "150px",
        sortable: false,
      },
      // コントロールNo
      {
        text: i18n.tc("label.lbl_controlNo"),
        value: "controlNo",
        align: "left",
        width: "200px",
        sortable: false,
      },
      // 出庫依頼者
      {
        text: i18n.tc("label.lbl_outBoundRequester"),
        value: "outBoundRequester",
        align: "left",
        width: "140px",
        sortable: false,
      },
      // 出荷No
      {
        text: i18n.tc("label.lbl_shippingNo"),
        value: "shippingNo",
        align: "left",
        width: "150px",
        sortable: false,
      },
      // 出荷予定日
      {
        text: i18n.tc("label.lbl_shippingScheduleDate"),
        value: "shippingDate",
        align: "left",
        width: "150px",
        sortable: false,
      },
      // 出荷実績日
      {
        text: i18n.tc("label.lbl_shipAchieveDate"),
        value: "shipedDate",
        align: "left",
        width: "150px",
        sortable: false,
      },
      // 出荷作業者
      {
        text: i18n.tc("label.lbl_shipedDate"),
        value: "shipper",
        align: "left",
        width: "150px",
        sortable: false,
      },

      // 引当
      {
        text: i18n.tc("btn.btn_reservation"),
        value: "splitSituation",
        align: "left",
        width: "100px",
        sortable: false,
      },
      // 出庫
      {
        text: i18n.tc("label.lbl_leaveWarehouseQty"),
        value: "shipmentSituation",
        align: "left",
        width: "100px",
        sortable: false,
      },
      // 出荷
      {
        text: i18n.tc("label.lbl_shippingDiv"),
        value: "shippingSituation",
        align: "left",
        width: "100px",
        sortable: false,
      },
    ],
    // バリデーション
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
    },
    appConfig: {
      DELIVERY_TYPE_DIV: appConfig.CODETYPE.DELIVERY_TYPE_DIV,
      OUT_REQUEST_DIV: appConfig.CODETYPE.OUT_REQUEST_DIV,
    },
  }),
  methods: {
    // ページング
    u_100_check() {
      if (this.itemsPerPage < 101) {
        this.u_10 = true;
      }
    },
    u_100(val) {
      if (val == 100) {
        this.u_10 = true;
        this.itemsPerPage = 100;
      } else {
        this.u_10 = false;
        this.itemsPerPage = val;
      }
      this.page = 1;
      this.getItemList();
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearBtnDialog() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearBtn;
    },
    /**
     * 画面クリア
     */
    clearBtn() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      if (this.searchForm) {
        // 品番/品名
        this.searchForm.itemSelected = "";
        //  所属
        this.searchForm.affiliationSelected = "";
        // 部品要求者
        this.searchForm.partsRequesterSelected = "";
        // グループ
        this.searchForm.txt_groupName = "";
        // 管理番号
        this.searchForm.managementNumber = "";
        // 納品種別
        this.searchForm.deliveryType = "";
        // 出庫依頼有無
        this.searchForm.outboundRequest = "";
        // 倉庫
        this.searchForm.warehouseSelected = "";
      }
      // 変更検知用の設定
      this.inOutScheduleDateFromCal = "";
      this.inOutScheduleDateToCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    // 初期化
    init() {
      this.defaultData = Object.assign({}, this.$data); // 画面クリアボタン押下時の処理
      this.inOutScheduleDateTo = dateTimeHelper.convertJST();
      this.inOutScheduleDateFrom = dateTimeHelper.convertJST();
      this.inOutScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.inOutScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      // 明細クリア
      this.inputList.splice(0);
    },

    /**
     * 出力ボタン押下時の処理
     */
    downloadList() {
      // データ出力ボタン押下時

      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter++;

        const config = this.$httpClient.createRequestBodyConfig();

        config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MNG_101;

        // 取引先
        config.reqIdv.clientSid = this.searchForm.supplierSelected;

        // 商品標準SID
        if (this.searchForm.itemSelected) {
          config.reqIdv.itemStandardSid = this.searchForm.itemSelected;
        }

        // 所属
        if (this.searchForm.affiliationSelected) {
          config.reqIdv.belongSid = this.searchForm.affiliationSelected;
        }

        // 部品要求者
        if (this.searchForm.partsRequesterSelected) {
          config.reqIdv.partsRequesterSid = this.searchForm.partsRequesterSelected;
        }

        // グループ
        if (this.searchForm.txt_groupName) {
          config.reqIdv.groupName = this.searchForm.txt_groupName;
        }

        // 管理番号
        if (this.searchForm.managementNumber) {
          config.reqIdv.itemManageNo = this.searchForm.managementNumber;
        }

        // 納品種別
        if (this.searchForm.deliveryType) {
          config.reqIdv.deliveryType = this.searchForm.deliveryType;
        }

        // 入出荷日From
        config.reqIdv.inOutScheduleDateFrom = dateTimeHelper.convertUTC(this.inOutScheduleDateFrom);

        // 入出荷日To
        var changedDate = this.inOutScheduleDateTo + " 23:59:59.999";
        config.reqIdv.inOutScheduleDateTo = dateTimeHelper.convertUTC(changedDate);

        // 倉庫
        if (this.searchForm.warehouseSelected) {
          config.reqIdv.warehouseSid = this.searchForm.warehouseSelected;
        }

        // 出庫依頼有無
        if (this.searchForm.outboundRequest) {
          config.reqIdv.outBoundRequest = this.searchForm.outboundRequest;
        }

        return new Promise((resolve, reject) => {
          // 既存処理
          return (
            this.$httpClient
              // 接続先のAPI_URLを入力
              .securePost(appConfig.API_URL.BIZ_WAREOUT_FILE_PARTS, config, appConfig.APP_CONFIG)
              .then((response) => {
                const jsonData = JSON.parse(JSON.stringify(response.data));

                // 正常時
                if (jsonData.resCom.resComCode == "000") {
                  // 返却されたurlを共通関数へ呼び出し
                  commonFunction.getLink(jsonData.resIdv.filePath);
                  resolve(response);
                } else {
                  // エラー時(件数0件またはapiエラー時)
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  reject();
                }
              })
              .catch((resolve) => {
                console.error("downloadList() Resolve", resolve);
                this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;
                reject(resolve);
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter--;
                this.dataOutPutDialog = false;
              })
          );
        });
      }
    },

    /**
     * 検索ボタン押下
     */
    clickSearchBtn() {
      this.page = 1;
      this.inputList.splice(0);
      this.getItemList();
    },

    // 検索ボタン押下後
    getItemList() {
      // 入力チェック結果フラグ
      this.alertMessage = "";
      this.alertSupplierMessage = "";
      this.alertCreateMessage = "";
      // Fromの日付がtoよりも先だった場合、エラー
      if (
        new Date(this.inOutScheduleDateFrom).getTime() >
        new Date(this.inOutScheduleDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        return;
      }
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter++;
        this.$set(this.inputList, "", "");
        // httpClientで使用する共通IFパラメータ等を作成する。
        const config = this.$httpClient.createGetApiRequestConfig();
        // 取引先SID
        config.params.clientSid = this.searchForm.supplierSelected;
        // 所属
        if (this.searchForm.affiliationSelected) {
          config.params.belongSid = this.searchForm.affiliationSelected;
        }
        //  商品標準SID
        if (this.searchForm.itemSelected) {
          config.params.itemStandardSid = this.searchForm.itemSelected;
        }
        //入出荷日From
        config.params.inOutScheduleDateFrom = dateTimeHelper.convertUTC(this.inOutScheduleDateFrom);

        //入出荷日To
        var changedDate = this.inOutScheduleDateTo + " 23:59:59.999";
        config.params.inOutScheduleDateTo = dateTimeHelper.convertUTC(changedDate);

        // 部品要求者
        if (this.searchForm.partsRequesterSelected) {
          config.params.partsRequesterSid = this.searchForm.partsRequesterSelected;
        }
        // グループ
        if (this.searchForm.txt_groupName) {
          config.params.groupName = this.searchForm.txt_groupName;
        }
        // 管理番号
        if (this.searchForm.managementNumber) {
          config.params.itemManageNo = this.searchForm.managementNumber;
        }
        // 納品種別
        if (this.searchForm.deliveryType) {
          config.params.deliveryType = this.searchForm.deliveryType;
        }
        // 出庫依頼有無
        if (this.searchForm.outboundRequest) {
          config.params.outBoundRequest = this.searchForm.outboundRequest;
        }
        // 倉庫
        if (this.searchForm.warehouseSelected) {
          config.params.warehouseSid = this.searchForm.warehouseSelected;
        }

        // ページング
        if (this.page >= 2 && this.inputList.length == 0) {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          config.params.reqComPageIndex = this.page;
        }

        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;

        // 接続先のAPI_URLを入力
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_WAREINOUT_PARTS, config)
            .then((response) => {
              this.inputList.splice(0);
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                // alert("API接続に成功しました。");
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
                this.totalCount = jsonData.resCom.resComCount;

                this.inOutList = jsonData.resIdv.inOutList;
                // for(){}繰り返し処理を行い、配列を格納する処理を記述する
                for (let i = 0; i < this.inOutList.length; i++) {
                  let data = {
                    receivingDate: this.inOutList[i].receivingDate
                      ? dateTimeHelper.convertUTC2JST(this.inOutList[i].receivingDate).substr(0, 10)
                      : "", //入荷実績日
                    receivingStock: this.inOutList[i].receivingStock, //入荷数
                    productCd: this.inOutList[i].itemCd, //品番/品名
                    productName: this.inOutList[i].itemName, //品名
                    affiliation: this.inOutList[i].belongName, //所属
                    partsRequester: this.inOutList[i].partsRequesterName, //部品要求者
                    group: this.inOutList[i].groupName, //グループ
                    controlNumber: this.inOutList[i].itemManageNo, //管理番号
                    warehouse: this.inOutList[i].warehouseName, //倉庫
                    deliveryType: this.inOutList[i].delivertyTypeName, //納品種別
                    controlNo: this.inOutList[i].controlNo, //コントロールNo
                    outBoundRequester: this.inOutList[i].outBoundRequesterName, // 出庫依頼者
                    shippingNo: this.inOutList[i].shippingNo, //出荷No
                    shippingDate: this.inOutList[i].outScheduleDate
                      ? dateTimeHelper
                          .convertUTC2JST(this.inOutList[i].outScheduleDate)
                          .substr(0, 10)
                      : "", //出荷予定日
                    shipedDate: this.inOutList[i].shippingDate
                      ? dateTimeHelper.convertUTC2JST(this.inOutList[i].shippingDate).substr(0, 10)
                      : "", //出荷実績日
                    shipper: this.inOutList[i].shipper, //出荷作業者
                    splitDiv: this.inOutList[i].splitDiv, //引当
                    splitSituation: this.setSituation(this.inOutList[i].splitDiv), // 引当区分名
                    shipmentDiv: this.inOutList[i].shipmentDiv, //出庫
                    shipmentSituation: this.setSituation(this.inOutList[i].shipmentDiv), // 出庫区分名
                    shippingDiv: this.inOutList[i].shippingDiv, //出荷
                    shippingSituation: this.setSituation(this.inOutList[i].shippingDiv), // 出荷区分名
                  };
                  this.inputList.push(data);
                }
                // エラー時
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;
              }
              resolve(response);
            })
            .catch((resolve) => {
              console.error("getItemList() Resolve", resolve);
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter--;
            });
        });
      }
    },
    /**
     * 入出荷日Fromを－１日します。
     */
    prevDate() {
      if (this.inOutScheduleDateFromCal == null) {
        return;
      }
      let date = new Date(this.inOutScheduleDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.inOutScheduleDateFromCal != toDate) {
        this.inOutScheduleDateFromCal = toDate;
      } else {
        this.inOutScheduleDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 入出荷日Fromを＋１日します。
     */
    nextDate() {
      if (this.inOutScheduleDateFromCal == null) {
        return;
      }
      let date = new Date(this.inOutScheduleDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.inOutScheduleDateFromCal != toDate) {
        this.inOutScheduleDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.inOutScheduleDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * 入出荷日Toを－１日します。
     */
    prevToDate() {
      if (this.inOutScheduleDateToCal == null) {
        return;
      }
      let date = new Date(this.inOutScheduleDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.inOutScheduleDateToCal != toDate) {
        this.inOutScheduleDateToCal = toDate;
      } else {
        this.inOutScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 入出荷日Toを＋１日します。
     */
    nextToDate() {
      if (this.inOutScheduleDateToCal == null) {
        return;
      }
      let date = new Date(this.inOutScheduleDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.inOutScheduleDateToCal != toDate) {
        this.inOutScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.inOutScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.inOutScheduleDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.inOutScheduleDateFromCal = null;
      }
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.inOutScheduleDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.inOutScheduleDateToCal = null;
      }
    },

    /**
     * 各区分名取得
     */
    setSituation(div) {
      var name = "";
      if (div == "01") {
        name = i18n.tc("label.lbl_already");
      } else {
        name = i18n.tc("label.lbl_notYet");
      }
      return name;
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    //入出荷日FROM
    inOutScheduleDateFromCal: {
      handler(val) {
        this.inOutScheduleDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    //入出荷日To
    inOutScheduleDateToCal: {
      handler(val) {
        this.inOutScheduleDateTo = this.formatDate(val);
      },
      deep: true,
    },
    page: function (newValue) {
      this.page = newValue;
      this.getItemList();
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.overflow {
  overflow: hidden;
}
.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
.link {
  text-decoration: none;
}
.table-style {
  overflow-x: auto;
}

.table-data-align-right {
  text-align: right;
}

.gap-3 > * {
  margin-left: 12px;
}
.gap-3 > *:last-child {
  margin-right: 12px;
}
</style>
